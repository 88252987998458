.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layoutContentWithScroll {
  display: flex;
  flex-direction: column;
  height: 50vh;
}

.layoutContent {
  flex: 1;
  padding: 1rem 3rem;
  background-color: #ffb600;
  overflow-y: auto;
}
