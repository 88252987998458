@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: hidden;
}

body,
html {
  margin: 0;
  padding: 0;
}

div[aria-label='PwC logo'] .ap-header-logo {
  background-color: white !important;
}

.ap-modal-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}

:root{
  --color-background-primary: #C92F2F !important;
  --color-text-primary: #171D53 !important;
}
