.loadingSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.renderAsPaged {
  height: calc(100vh - 110px);
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid rgb(229, 164, 0);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.spinnerSecondaryColor {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid rgb(219, 83, 106);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingSpinner p {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #333;
}

.whiteText {
  color: white !important;
}
