.globalErrorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 0, 0, 0.1); /* Light red background */
}

.globalErrorMessage {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.globalErrorMessage h2 {
    margin-bottom: 10px;
    color: #ff0000; /* Red text color */
}

.globalErrorMessage p {
    margin-bottom: 20px;
    color: #333; /* Dark gray text */
}

.globalErrorRetryButton {
    padding: 10px 20px;
    background-color: #ff0000; /* Red button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.globalErrorRetryButton:hover {
    background-color: #cc0000; /* Darker red on hover */
}
